import React, { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useEffect } from "react";

const Deleted = () => {
  const { url } = useContext(AppContext);
  const [deleted, setDeleted] = useState([]);
  useEffect(() => {
    const getDeleted = async () => {
      try {
        const smlAdmin = localStorage.getItem("smlAdmin");
        const res = await axios.get(`${url}/user/delete`, {
          headers: {
            token: JSON.parse(smlAdmin),
          },
        });
        if (res.data.success === true) {
          setDeleted(res.data.result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDeleted();
  }, []);
  return (
    <>
      <div className="container p-2">
        {deleted.length !== 0 ? (
          <>
            <div className="creditor-container">
              <table className="table table-bordered table-hover creditor-table">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Name</th>
                    <th>Email address</th>
                    <th>Mobile number</th>
                  </tr>
                </thead>
                <tbody>
                  {deleted.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.mobile}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>{" "}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Deleted;
