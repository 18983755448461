import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  const { url } = useContext(AppContext);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const signup = async () => {
    const { username, password } = inputs;
    if (!username || !password) {
      setError(true);
      setMessage("Enter your complete details");
    } else {
      const data = {
        username,
        password,
      };
      try {
        const res = await axios.post(`${url}/admin/signup`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.status === 200 && res.data.success === false) {
          setError(true);
          setMessage("Account already exists. Please login.");
        } else {
          setError(false);
          setMessage("Account created. Please login to continue");
          setTimeout(() => {
            navigate("/login", { replace: true });
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <div className="container py-4 px-2 mt-2">
        <div className="enroll-form">
          <div className="d-flex align-items-center justify-content-evenly mb-4">
            <Link to="/login" className="white-button">
              <h2>Login</h2>
            </Link>
            <Link to="/signup" className="button">
              <h2>Signup</h2>
            </Link>
          </div>
          <div className="form-div mb-4">
            <label>Username</label>
            <input
              type="text"
              className="input"
              name="username"
              value={inputs.username}
              onChange={handleInputs}
            />
          </div>
          <div className="form-div mb-4">
            <label>Password</label>
            <input
              type="password"
              className="input"
              name="password"
              value={inputs.password}
              onChange={handleInputs}
            />
          </div>
          <div className="text-end">
            <p className={error ? "text-danger" : "text-success"}>{message}</p>
            <button className="button" onClick={signup}>
              Signup
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
