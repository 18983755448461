import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import CreditorModal from "./CreditorModal";

const Card = ({ users }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [creditors, setCreditors] = useState(null);
  const openModal = (item) => {
    setModalVisible(true);
    setCreditors(item);
  };
  return (
    <>
      {users.map((item, index) => {
        return (
          <div className="my-card" key={index}>
            <div>
              <p>
                <span className="fw-bold">Name:</span> <span>{item.name}</span>
              </p>
              <p>
                <span className="fw-bold">Email address:</span>{" "}
                <span>{item.email}</span>
              </p>
              <p>
                <span className="fw-bold">Mobile number:</span>{" "}
                <span>{item.mobile}</span>
              </p>
            </div>
            <div>
              <p>
                <span className="fw-bold">Creditors:</span>{" "}
              </p>
              <button
                className="button d-flex align-items-center justify-content-between gap-2"
                onClick={() => openModal(item.creditor)}
              >
                {item.creditor && item.creditor.length}
                <FaEye />
              </button>
            </div>
            <div>
              <p>
                <span className="fw-bold">Summary:</span>{" "}
              </p>
              {item.summary && (
                <ul className="list-unstyled">
                  <li>
                    <span className="fw-bold">Secured outstanding:</span>{" "}
                    <span>AED {item.summary.securedOutstanding}</span>
                  </li>
                  <li>
                    <span className="fw-bold">Unsecured outstanding:</span>{" "}
                    <span>AED {item.summary.unsecuredOutstanding}</span>
                  </li>
                  <li>
                    <span className="fw-bold">Priority EMI (Secured):</span>{" "}
                    <span>AED {item.summary.priorityEMI}</span>
                  </li>
                  <li>
                    <span className="fw-bold">Unsecured EMI:</span>{" "}
                    <span>AED {item.summary.unsecuredEMI}</span>
                  </li>
                </ul>
              )}
            </div>
            <div>
              <p>
                <span className="fw-bold">Income:</span>{" "}
              </p>
              <ul className="list-unstyled">
                <li>
                  <span className="fw-bold">Salary:</span>{" "}
                  <span>AED {item.salary}</span>
                </li>
                <li>
                  <span className="fw-bold">Business income:</span>{" "}
                  <span>AED {item.businessIncome}</span>
                </li>
                <li>
                  <span className="fw-bold">Family support:</span>{" "}
                  <span>AED {item.familySupport}</span>
                </li>
                <li>
                  <span className="fw-bold">Other income:</span>{" "}
                  <span>AED {item.otherIncome}</span>
                </li>
              </ul>
            </div>
            <div>
              <p>
                <span className="fw-bold">Living expense:</span>{" "}
              </p>
              <ul className="list-unstyled">
                <li>
                  <span className="fw-bold">Rent/Maintenance:</span>{" "}
                  <span>AED {item.rent}</span>
                </li>
                <li>
                  <span className="fw-bold">Food Expense:</span>{" "}
                  <span>AED {item.food}</span>
                </li>
                <li>
                  <span className="fw-bold">Electricity Bill:</span>{" "}
                  <span>AED {item.electricity}</span>
                </li>
                <li>
                  <span className="fw-bold">Gas Bill:</span>{" "}
                  <span>AED {item.gas}</span>
                </li>
                <li>
                  <span className="fw-bold">Other Utilities:</span>{" "}
                  <span>AED {item.other}</span>
                </li>
              </ul>
            </div>
            <div>
              <p>
                <span className="fw-bold">Lifestyle expense:</span>{" "}
              </p>
              <ul className="list-unstyled">
                <li>
                  <span className="fw-bold">Travel/Fuel:</span>{" "}
                  <span>AED {item.travel}</span>
                </li>
                <li>
                  <span className="fw-bold">Digital Subscription:</span>{" "}
                  <span>AED {item.subscription}</span>
                </li>
                <li>
                  <span className="fw-bold">Dining Out:</span>{" "}
                  <span>AED {item.dining}</span>
                </li>
                <li>
                  <span className="fw-bold">House Help:</span>{" "}
                  <span>AED {item.houseHelp}</span>
                </li>
                <li>
                  <span className="fw-bold">Outing:</span>{" "}
                  <span>AED {item.outing}</span>
                </li>
              </ul>
            </div>
          </div>
        );
      })}

      <CreditorModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        creditors={creditors}
      />
    </>
  );
};

export default Card;
