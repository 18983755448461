import React from "react";
import { Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Auth from "./utility/Auth";
import Deleted from "./pages/Deleted";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Auth Component={Home} />} />
        <Route path="/delete" element={<Auth Component={Deleted} />} />

        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};

export default App;
