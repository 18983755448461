import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import Card from "../components/Card";

const Home = () => {
  const { url } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  //
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  //
  const getUsers = async (page) => {
    try {
      const smlAdmin = localStorage.getItem("smlAdmin");
      const res = await axios(`${url}/users?page=${page}`, {
        headers: {
          token: JSON.parse(smlAdmin),
        },
      });
      setUsers(res.data.result);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers(currentPage);
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //
  const renderPaginationItems = () => {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(number)}
          >
            {number}
          </button>
        </li>
      );
    }
    return items;
  };
  //
  return (
    <>
      <div className="container p-2">
        <div className="card-container">
          <Card users={users} />
        </div>
        <ul className="pagination justify-content-end mt-2">
          {renderPaginationItems()}
        </ul>
      </div>
    </>
  );
};

export default Home;
