import React from "react";
import { FaTimes } from "react-icons/fa";

const CreditorModal = ({ modalVisible, setModalVisible, creditors }) => {
  return (
    <>
      <div className={`modal-background ${modalVisible ? "active" : ""}`}>
        <div className="modal-container">
          <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <h2>Creditors</h2>
            <button
              className="white-button"
              onClick={() => setModalVisible(false)}
            >
              <FaTimes />
            </button>
          </div>
          {creditors === null || creditors.length === 0 ? (
            <p className="text-center">No data found</p>
          ) : (
            creditors.map((item, index) => {
              return (
                <ul
                  className={`list-unstyled ${
                    index !== creditors.length - 1 ? "mb-4" : ""
                  }`}
                  key={index}
                >
                  <li>
                    <span className="fw-bold">Name:</span>{" "}
                    <span>{item.creditorName}</span>
                  </li>
                  <li>
                    <span className="fw-bold">Debt type:</span>{" "}
                    <span>{item.debtType}</span>
                  </li>
                  <li>
                    <span className="fw-bold">Outstanding:</span>{" "}
                    <span>AED {item.outstanding}</span>
                  </li>
                  <li>
                    <span className="fw-bold">EMI:</span>{" "}
                    <span>AED {item.emi}</span>
                  </li>
                  <li>
                    <span className="fw-bold">Missed EMI:</span>{" "}
                    <span>{item.missedEMI}</span>
                  </li>
                </ul>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default CreditorModal;
