import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/Navbar";

const Auth = ({ Component }) => {
  const { url, setAdmin } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    const auth = async () => {
      try {
        const smlAdmin = localStorage.getItem("smlAdmin");
        if (!smlAdmin) {
          navigate("/login", { replace: true });
        } else {
          const res = await axios.get(`${url}/admin/auth`, {
            headers: {
              token: JSON.parse(smlAdmin),
            },
          });
          if (res.status === 200 && res.data.success === true) {
            setAdmin(res.data.result);
          } else if (res.status === 401) {
            navigate("/login", { replace: true });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login", { replace: true });
        } else {
          console.log(error);
        }
      }
    };
    auth();
  }, []);
  //
  return (
    <>
      <Navbar />
      <Component />
    </>
  );
};

export default Auth;
