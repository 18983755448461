import React from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { ImSwitch } from "react-icons/im";
import logo from "../assets/images/logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("smlAdmin");
    navigate("/login", { replace: true });
  };
  return (
    <>
      <nav className="container-fluid p-2">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <Link to="/" className="logo">
              <img src={logo} alt="" />
            </Link>

            <ul className="d-flex align-items-center justify-content-between gap-4">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/delete">Delete</NavLink>
              </li>
              <li onClick={logout}>
                <ImSwitch className="user-icon" />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
